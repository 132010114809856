.hideStatusList {
  right: -250px;
}

.showStatusList {
  right: 0;
}

.statusListCtn {
  transform: translateY(-50%);
  height: 250px;

  .hover:hover {
    background-color: #2F80Ed;
  }

  .statusList {
    height: 100%;
    width: 250px
  }

  .showStatusBtn {
    height: 100px;
    width: 30px;
  }

  .fontSize45 {
    font-weight: 200;
    font-size: 45px;
  }
}

/* Responsive */

@media (max-width: 950px) {
  .statusListCtn {
    height: 200px;

    .statusList {
      width: 200px;
    }

    .fontSize16 {
      font-size: 12px;
    }
  }

  .hideStatusList {
    right: -200px;
  }
}

@media (max-height: 350px) {
  .statusListCtn {
    height: 80%;
  }
}