.tooltip {
  .icon {
    color: #BDBDBD;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    font-size: 20px;
  }

  .text {
    background-color: rgba(0, 0, 0, 0.6);
    width: 120px;
    padding: 6px 12px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Responsive */

@media (max-width: 912px) {
  .tooltip {
    span {
      font-size: 16px;
    }
  }
}