@use '../../index' as v;

button {
  font-size: 14px;
  padding: 10px 20px;
  color: var(--button-text-color);
  background-color: var(--button-background-color);

  &:hover {
    background-color: var(--button-background-color-hover);
  }
}

/* Responsive */

@media (max-width: 950px) {
  button {
    font-size: 8px;
    padding: 5px 10px;
  }
}