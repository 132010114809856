.card {
  width: 800px;
  height: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 0 50px;
  box-sizing: border-box;
}

.radiusCard {
  border-radius: 50px 50px 0 0;
}

/* Responsive */

@media (max-width: 950px) {
  .card {
    width: 80%;
    height: 200px;
  }

  .eta {
    padding: 0 30px;
  }

  .ps {
    padding: 0 20px;
  }

  .radiusCard {
    border-radius: 30px 30px 0 0;
  }
}

@media (max-width: 500px) {
  .card {
    width: 90%;
  }
}

@media (max-width: 300px) {
  .card {
    width: 95%;
  }
}

@media (max-height: 500px) {
  .card {
    width: 80%;
    height: 150px;
    // height: 30%;
  }
}