.detailsCtn {
  width: 500px;
  height: auto;

  .height80 {
    height: calc(80% - 32px)
  }

  .hover:hover {
    background-color: #e22e2e;
  }

  .pclose {
    padding: 2px 12px 0;
  }
}

@media (max-width: 800px) {
  .detailsCtn {
    min-width: auto;
    width: 90%;
    height: 70%;
    padding: 16px;
    font-size: 14px;

    .width80 {
      width: 100%;
    }

    .ctn {
      height: auto
    }

    .title:first-child {
      font-size: 18px;
    }
  }
}

@media (max-height: 700px) {
  .detailsCtn {
    height: 90%;

    .ctn {
      height: auto
    }
  }
}