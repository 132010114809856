@import "src/style/variables";

.registration:hover {
  color: $blue-color;
}


/* Responsive */

@media (max-width: 950px) {
  .psCard {
    .fontSize16 {
      font-size: 12px;
    }

    .fontSize14 {
      font-size: 10px;
    }

    .gap32 {
      gap: 4px;
    }

    .gap24 {
      gap: 8px;
    }
  }
}