.etaCard {
  .width33 {
    width: calc(100% / 3);
  }

  .width66 {
    width: calc(100% / 3 * 2);
  }

  & > div {
    gap: 24px;
  }
}

/* Responsive */

@media (max-width: 950px) {
  .etaCard {
    & > div {
      gap: 8px;
    }

    .fontSize16 {
      font-size: 10px;
    }

    .fontSize18 {
      font-size: 12px;
    }

    .fontSize20 {
      font-size: 14px;
    }

  }
}
