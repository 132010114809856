@import "src/style/variables";

.detailsVehicle {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
  }

  span:nth-child(3n+2) {
    justify-self: center;
  }

  span:nth-child(3n+3) {
    justify-self: end;
  }

  .separator-line {
    width: 100%;
    background-color: #616161;
    height: 1px;
  }

  .breakWord {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    display: inline-block;
  }

  .hoverRegistration:hover {
    color: $blue-color;
  }

}

@media (max-width: 700px) {
  .detailsVehicle {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }

    span:nth-child(2n+1) {
      justify-self: start;
    }

    span:nth-child(2n+2) {
      justify-self: end;
    }
  }
}