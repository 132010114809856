#map {

  .gm-style-iw {
    outline: none !important;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    width: 300px;
  }

  .gm-style iframe + div {
    border: none !important;
  }

  // .gm-ui-hover-effect {
  //   display: none !important;
  // }
}