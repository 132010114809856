.widthHeightIcon {
  width: 40px;
  height: 40px;
}

/* Responsive */

@media (max-width: 950px) {
  .widthHeightIcon {
    width: 35px;
    height: 35px;
  }
}