/** reset css **/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1;
  width: 100vw;
  height: 100vh;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*btn*/
button {
  border: none;
  padding: 0;
  cursor: pointer;
}

/* Import scss */

@import "src/style/variables";

#root {
  width: 100%;
  height: 100%;
  font-family: $font-family;
}

/** scrollbar **/
.scrollbarLight::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}

.scrollbarLight::-webkit-scrollbar-track {
  background: $grey-light-color;
  border-radius: 50px;
}

.scrollbarLight::-webkit-scrollbar-thumb {
  background: $grey-color;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.scrollbar::-webkit-scrollbar-track {
  background: $grey-light-color;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: $grey-color;
  border-radius: 50px;

}

.scrollbarRadius::-webkit-scrollbar {
  border-radius: 25px;
}

/** Class **/

.pointer {
  cursor: pointer;
}

.noEvents {
  pointer-events: none;
}

/*position*/
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top {
  top: 0;
}

.top4 {
  top: 4px
}

.top8 {
  top: 8px;
}

.top50Pct {
  top: 50%;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.left4 {
  left: 4px;
}

.right {
  right: 0;
}

.right4 {
  right: 4px;
}

.right8 {
  right: 8px;
}

/* overflow */
.overflow {
  overflow: auto;
}

.overflowX {
  overflow-x: auto;
}

.overflowY {
  overflow-y: auto;
}

.overflowHidden {
  overflow: hidden;
}

/*transitions*/

.trans02s {
  transition: .2s;
}

.trans04s {
  transition: .4s;
}

/*zIndex*/

.zIndex {
  z-index: 999;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

.zIndex4 {
  z-index: 4;
}

.zIndex5 {
  z-index: 5;
}

/*Font*/
.poppins {
  font-family: $font-family;
}

.poppinsItalic {
  font-family: $font-family;
  font-style: italic;
}

.poppinsMedium {
  font-family: $font-family;
  font-weight: 500;
}

.poppinsBold {
  font-family: $font-family;
  font-weight: bold;
}

.fontSize8 {
  font-size: $font-size-8;
}

.fontSize10 {
  font-size: $font-size-10;
}

.fontSize12 {
  font-size: $font-size-12;
}

.fontSize14 {
  font-size: $font-size-14;
}

.fontSize16 {
  font-size: $font-size-16;
}

.fontSize18 {
  font-size: $font-size-18;
}

.fontSize20 {
  font-size: $font-size-20;
}

.fontSize22 {
  font-size: $font-size-22;
}

.fontSize24 {
  font-size: $font-size-24;
}

/*Color*/

.primaryColor {
  color: $primary-color;
}

.secondaryColor {
  color: $secondary-color;
}

.tertiaryColor {
  color: $tertiary-color;
}

.colorWhite {
  color: white;
}

/*Background*/

.backWhite {
  background-color: $white-color;
}

.backPrimary {
  background-color: $primary-color;
}

.backBlackTransparent {
  background-color: rgba(0, 0, 0, 0.3);
}

.backRed {
  background-color: $red-light-color;
}

.backDarkRed {
  background-color: $red-dark-color;
}

/*Width Height*/

.width100 {
  width: 100%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width50 {
  width: 50%;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.height100 {
  height: 100%;
}

.height80 {
  height: 80%;
}

.height75 {
  height: 75%;
}

.height70 {
  height: 70%;
}

.height30 {
  height: 30%;
}

.height20 {
  height: 20%;
}

.height50 {
  height: 50%;
}

/*Flex-box*/

@mixin flex($justify, $align, $direction) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

.flexHorizontalCenter {
  @include flex(center, center, row);
}

.flexHorizontal {
  @include flex(flex-start, center, row);
}

.flexHorizontalSpaceBetweenStart {
  @include flex(space-between, flex-start, row);
}

.flexHorizontalStart {
  @include flex(flex-start, start, row);
}

.flexVertical {
  @include flex(flex-start, center, column);
}

.flexVerticalCenter {
  @include flex(center, center, column);
}

.flexVerticalStart {
  @include flex(flex-start, flex-start, column);
}

.flexVerticalCenterStart {
  @include flex(center, start, column);
}

.flexSpaceBetweenCenter {
  @include flex(space-between, center, row);
}

.flexVerticalEnd {
  @include flex(flex-start, end, column);
}

.flexSpaceBetweenVertical {
  @include flex(space-between, center, column);
}

.flexWrap {
  flex-wrap: wrap;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

/*Shadow*/

.shadow {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/*Border-radius*/

@mixin radius($topLeft, $topRight, $bottomRight, $bottomLeft) {
  border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}

.radius2 {
  border-radius: 2px;
}

.radius4 {
  border-radius: 4px;
}

.radius6 {
  border-radius: 6px;
}

.radius8 {
  border-radius: 8px;
}

.radius50Top {
  @include radius(50px, 50px, 0, 0);
}

.radius4Left {
  @include radius(4px, 0, 0, 4px);
}

.radius8Left {
  @include radius(8px, 0, 0, 8px);
}

/* Text */

.alignText {
  text-align: center;
}

/*margin & padding*/

.mB8 {
  margin-bottom: 8px;
}

.mB16 {
  margin-bottom: 16px;
}

.mB32 {
  margin-bottom: 32px;
}

.mB42 {
  margin-bottom: 42px;
}

.mL8 {
  margin-left: 8px;
}

.mL16 {
  margin-left: 16px;
}

.mL32 {
  margin-left: 32px;
}

.mR8 {
  margin-right: 8px;
}

.mR16 {
  margin-right: 16px;
}

.mR32 {
  margin-right: 32px;
}

.mT8 {
  margin-top: 8px;
}

.mT16 {
  margin-top: 16px;
}

.mT32 {
  margin-top: 32px;
}

.mT64 {
  margin-top: 64px;
}

.mSide10 {
  margin: 0 10px;
}

.p4 {
  padding: 4px;
}

.p8 {
  padding: 8px;
}

.p16 {
  padding: 16px;
}

.p32 {
  padding: 32px;
}

.p48 {
  padding: 4px 8px;
}

.borderBox {
  box-sizing: border-box;
}

/*page unavailable*/
.backPageUnavailable {
  background-image: url("/res/img/backLoader-min.jpg");
  background-size: cover;
  background-position: center;
}

.fontSize96 {
  letter-spacing: 3px;
  font-size: 96px;
  font-weight: 600;
}

/* responsive font-size */
@media (max-width: 912px) {
  .fontSize24 {
    font-size: 16px;
  }

  .fontSize96 {
    font-size: 48px;
  }
}

.textStrokeWhite {
  letter-spacing: 3px;
  -webkit-text-stroke: 1px rgb(189, 189, 189);
  color: transparent;
  transform: translate(8px, 2px);
}

.textUppercase {
  text-transform: uppercase;
}

.textLowercase {
  text-transform: lowercase;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.line {
  background-color: #E9E9E9;
  width: 100%;
  height: 1px;
}