/** Variables **/

$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;

$font-family: 'Poppins', sans-serif;

$primary-color: #2196F3;
$secondary-color: #424242;
$tertiary-color: #757575;
$red-light-color: #E57373;
$red-dark-color: #e22e2e;
$blue-color: #2F80ED;
$white-color: white;
$grey-color: #9e9e9e;
$grey-dark-color: #616161;
$grey-light-color: #e0e0e0;